var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.post)?_c('v-container',{staticClass:"article-detail",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('div',{staticClass:"px-0 pt-0 d-flex align-center"},[_c('router-link',{staticClass:"d-flex align-center justify-center previous-page black--text",attrs:{"to":{
            name: 'ArticleList'
          }}},[_c('v-icon',{staticClass:"pr-2"},[_vm._v("$arrowBack")]),_c('span',{staticClass:"h4"},[_vm._v(_vm._s(_vm.$t("article.goBack")))])],1)],1),_c('h1',{staticClass:"mt-3 mb-6",domProps:{"innerHTML":_vm._s(_vm.post.title.rendered)}}),_c('v-col',{staticClass:"article-detail-information d-flex flex-column flex-sm-row align-start align-sm-center justify-space-between pa-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('router-link',{attrs:{"to":{
              name: 'ArticleList',
              query: { cat: _vm.post.categories[0].toString() }
            }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("$folder")]),_c('span',{staticClass:"font-weight-bold primary--text"},[_vm._v(" "+_vm._s(_vm.post.category_names[1])+" ")])],1)],1),_c('div',{staticClass:"d-flex justify-center align-center mt-3 mt-sm-0"},[_c('router-link',{attrs:{"to":{
              name: 'ArticleList',
              query: { cat: _vm.post.categories[0].toString() }
            }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("$tag")]),_c('span',{staticClass:"font-weight-bold primary--text"},[_vm._v(" "+_vm._s(_vm.post.category_names[0])+" ")])],1)],1),_c('span',{staticClass:"mt-3 mt-sm-0"},[_vm._v(_vm._s(`pubblicato il ${_vm.$dayjs(_vm.post.date).format("DD/MM/YYYY")} `))]),_c('ShareButtons',{attrs:{"post":_vm.post,"direction":"bottom","extended":true}})],1),_c('v-img',{staticClass:"my-4",attrs:{"src":_vm.post.featured_image_url}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.post.content.rendered)}}),(_vm.post.box_2)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.post.box_2)}}):_vm._e(),(_vm.post.box_3)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.post.box_3)}}):_vm._e(),(_vm.post.box_4)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.post.box_4)}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('PostSidebar')],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('h4',{staticClass:"mb-10"},[_vm._v(_vm._s(_vm.$t("posts.mby_interest")))]),_c('CardSlider',{attrs:{"cat":_vm.post.categories[0].toString()}})],1),_c('v-row',{staticClass:"mt-10",attrs:{"no-gutters":""}},[_c('v-col',[_c('PostFooter')],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }