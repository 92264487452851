<template>
  <v-container fluid v-if="post" class="article-detail">
    <!-- TODO - BREADCRUMB  -->
    <v-row>
      <v-col cols="12" md="9">
        <div class="px-0 pt-0 d-flex align-center">
          <router-link
            class="d-flex align-center justify-center previous-page black--text"
            :to="{
              name: 'ArticleList'
            }"
          >
            <v-icon class="pr-2">$arrowBack</v-icon>
            <span class="h4">{{ $t("article.goBack") }}</span></router-link
          >
        </div>
        <h1 class="mt-3 mb-6" v-html="post.title.rendered"></h1>
        <v-col
          cols="12"
          class="article-detail-information d-flex flex-column flex-sm-row align-start align-sm-center justify-space-between pa-0"
        >
          <div class="d-flex justify-center align-center">
            <router-link
              :to="{
                name: 'ArticleList',
                query: { cat: post.categories[0].toString() }
              }"
            >
              <v-icon class="mr-2" color="primary">$folder</v-icon>
              <span class="font-weight-bold primary--text">
                {{ post.category_names[1] }}
              </span>
            </router-link>
          </div>
          <div class="d-flex justify-center align-center mt-3 mt-sm-0">
            <router-link
              :to="{
                name: 'ArticleList',
                query: { cat: post.categories[0].toString() }
              }"
            >
              <v-icon class="mr-2" color="primary">$tag</v-icon>
              <span class="font-weight-bold primary--text">
                {{ post.category_names[0] }}
              </span>
            </router-link>
          </div>
          <span class="mt-3 mt-sm-0">{{
            `pubblicato il ${$dayjs(post.date).format("DD/MM/YYYY")} `
          }}</span>
          <ShareButtons
            :post="post"
            direction="bottom"
            :extended="true"
          ></ShareButtons>
        </v-col>
        <v-img class="my-4" :src="post.featured_image_url"></v-img>
        <p v-html="post.content.rendered"></p>

        <div v-if="post.box_2" v-html="post.box_2"></div>
        <div v-if="post.box_3" v-html="post.box_3"></div>
        <div v-if="post.box_4" v-html="post.box_4"></div>
      </v-col>
      <v-col cols="12" md="3">
        <PostSidebar></PostSidebar>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <h4 class="mb-10">{{ $t("posts.mby_interest") }}</h4>
      <CardSlider :cat="post.categories[0].toString()"></CardSlider>
    </v-row>
    <v-row no-gutters class="mt-10">
      <v-col>
        <PostFooter></PostFooter>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped lang="scss">
.article-detail {
  .previous-page:has(.v-icon) {
    text-decoration: none !important;
    > span {
      text-decoration: underline;
    }
  }
  &-information {
    h2 {
      margin: 20px 0px;
    }
    h3 {
      margin: 10px 0px;
    }
    img {
      margin-top: 20px;
      width: 100%;
      height: auto;
    }
    a {
      text-decoration: none !important;
    }
  }
  .social-share,
  .share-btn {
    cursor: pointer;
    cursor: -moz-pointer;
    cursor: -webkit-pointer;
  }
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";
import categoryMixins from "~/mixins/category";
import productMixin from "~/mixins/product";
import login from "~/mixins/login";
import CMService from "~/service/cmService";
import CardSlider from "@/components/wordpress/CardSlider.vue";
import ShareButtons from "@/components/wordpress/ShareButtons.vue";
import PostSidebar from "@/components/wordpress/PostSidebar.vue";
import PostFooter from "@/components/wordpress/PostFooter.vue";
export default {
  name: "ArticleDetail",
  mixins: [clickHandler, categoryMixins, productMixin, login],
  components: {
    CardSlider,
    ShareButtons,
    PostSidebar,
    PostFooter
  },
  data() {
    return {
      post: null,
      interestingArticles: null,
      open: false
    };
  },
  mounted() {
    this.fetchBlog(this.$route.params.postName);
  },
  methods: {
    async fetchBlog(slug) {
      const res = await CMService.getCustomPostBySlug("posts", slug);
      if (res && res.length) {
        this.post = res[0];
      }
      this.interestingArticles = this.fetchInterestingArticles();
    },
    async fetchInterestingArticles() {
      const interestingArticles = await CMService.getCustomPostByFilters(
        "posts",
        [
          {
            filterName: "categories",
            filterValue: this.post.categories[0]
          },
          {
            filterName: "orderby",
            filterValue: "date"
          },
          {
            filterName: "order",
            filterValue: "desc"
          }
        ],
        8
      );
      if (
        interestingArticles &&
        interestingArticles.data &&
        interestingArticles.data.length
      ) {
        this.interestingArticles = interestingArticles.data;
      } else {
        this.interestingArticles = null;
      }
      console.log(interestingArticles);
    }
  },
  watch: {
    "$route.params.postName"() {
      this.fetchBlog(this.$route.params.postName);
    }
  },
  jsonld() {
    if (this.page) {
      return {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": this.page.link
        },
        headline: this.page.meta ? this.page.meta.seo_title : "",
        image: ["/logo/social-logo.png"],
        author: {
          "@type": "Organization",
          name: global.config?.defaultPageName
        },
        datePublished: this.page.date,
        dateModified: this.page.date,
        publisher: {
          "@type": "Organization",
          name: global.config?.defaultPageName,
          logo: {
            "@type": "ImageObject",
            url: "/logo/social-logo.png"
          }
        }
      };
    }
  },
  metaInfo() {
    if (this.post) {
      return {
        title: this.post.title.rendered,
        link: [
          {
            rel: "canonical",
            href: `https://${window.location.host}${this.$route.path}`
          }
        ],
        meta: [
          {
            vmid: "description",
            name: "description",
            content: this.post.title.rendered
          },
          // Open Graph Metas
          {
            property: "og:locale",
            content: "it_IT"
          },
          {
            property: "og:title",
            content: this.post.title.rendered
          },
          {
            property: "og:type",
            content: "website"
          },
          {
            property: "og:url",
            content: location.href
          },
          {
            property: "og:image",
            content:
              "https://" + window.location.host + global.config.socialLogo ||
              "/logo/social-logo.png"
          },
          {
            property: "og:image:alt",
            content: this.post.title.rendered
          },
          {
            property: "og:site_name",
            content: global.config?.defaultPageName
          },
          // Twitter Metas
          {
            name: "twitter:card",
            content: "summary_large_image"
          },
          {
            name: "twitter:title",
            content: this.post.title.rendered
          },
          {
            name: "twitter:image",
            content:
              "https://" + window.location.host + global.config.socialLogo ||
              "/logo/social-logo.png"
          },
          {
            name: "twitter:url",
            content: location.href
          }
        ]
      };
    }
  }
};
</script>
